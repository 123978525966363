import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./argon-dashboard";
import vue3GoogleLogin from 'vue3-google-login'
import "./assets/css/nucleo-icons.css";
import "./assets/css/fym-custom.css";
import "./assets/css/fontawesome.css";

import "./assets/js/timeout.js";

const appInstance = createApp(App);

// Fetch runtime environment variables
//fetch('/config/config.json')
//  .then(response => response.json())
//  .then(config => {

    appInstance.use(store);
    appInstance.use(router);
    appInstance.use(ArgonDashboard);

    appInstance.use(vue3GoogleLogin, {
      //TeamFYMApp Project in Google Cloud Console
      clientId: '670873632958-s1dvoc1cdvg66lq8v58uu1qnc35t2msn.apps.googleusercontent.com'
    })

    const hostname = window.location.hostname;
    //console.log('hostname: ' + hostname);
    if(hostname==='app.teamfym.com') {
      appInstance.config.globalProperties.$version = '1.0.0';
      appInstance.config.globalProperties.$node_env = 'production';
      appInstance.config.globalProperties.$hostname = 'https://app.teamfym.com';
      appInstance.config.globalProperties.$api_url = 'https://app.teamfym.com';

    }
    else if(hostname==='dev.local.teamfym.com') {
      appInstance.config.globalProperties.$version = '1.0.0';
      appInstance.config.globalProperties.$node_env = 'development';
      appInstance.config.globalProperties.$hostname = 'http://dev.local.teamfym.com';
      appInstance.config.globalProperties.$api_url = 'https://dev-k8s.teamfym.local';
    }
    else if(hostname==='dev-k8s.teamfym.com') {
      appInstance.config.globalProperties.$version = '1.0.0';
      appInstance.config.globalProperties.$node_env = 'development';
      appInstance.config.globalProperties.$hostname = 'https://dev-k8s.teamfym.com';
      appInstance.config.globalProperties.$api_url = 'https://dev-k8s.teamfym.com';
    }
    else {
      appInstance.config.globalProperties.$version = '1.0.0';
      appInstance.config.globalProperties.$node_env = 'localhost';
      appInstance.config.globalProperties.$hostname = 'http://localhost:8080';
      appInstance.config.globalProperties.$api_url = 'http://localhost:8080';
    }

  appInstance.mount("#app");

//})
//.catch(error => {
//  console.error('Error loading config:', error);
//});