<template>

    <!-- Modal Div -->
    <div v-show="isModalVisible" class="modal-container">
      <div class="modal-content">
        <button @click="hideModal" class="close-btn">×</button><br>
 
        <div class="py-4 container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <component :is="modalComponent" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{this.company}} Policy Data</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>

    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
                <argon-button color="success" size="sm" class="ms-auto" @click="exportToExcel">Export to Excel</argon-button>
              </div>
            </div>
            <div class="card-body">
              
              <div v-if="!isAgent" class="col-md-12">
                <select id="filter" name="filter" class="form-select-sm" v-model="selectedFilter" @change.prevent="filter()">
                  <option v-for="element in filters" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                &nbsp;
                <select v-if="!isAgent && selectedFilter == 5" id="filter2" name="filter2" class="form-select-sm" v-model="selectedFilter2" @change.prevent="filter2()">
                  <option v-for="element in filters2" :value="element.name" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                <a href=# @click.prevent="search()">&nbsp;<i class="fa fa-magnifying-glass text-success text-sm opacity-10"></i></a>
                <br><br>
                <input v-if="!isAgent" type="text" id="searchVal" name="searchVal" class="form-control" v-model="searchVal" title="Search" placeholder="Search" @change.prevent="validate()"></input>&nbsp;
                &nbsp;&nbsp;
              </div> 

              <div class="col-md-12">
                Year
                <select id="year" name="year" class="form-select" v-model="selectedYear" @change="search()" required>
                  <option v-for="element in years" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                Month
                <select id="months" name="months" class="form-select" v-model="selectedMonth" @change="search()" required>
                  <option v-for="element in months" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                Period
                <select id="period" name="period" class="form-select" v-model="selectedPeriod" @change="search()" required>
                  <option v-for="element in periods" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>

              </div>
              <br>

              <div class="col-md-12 scrollable-div">
                <div class="content">
                  <ul class="ul">
                    <li>

                      <span class="td1 heading">
                        Policy Age
                      </span>

                      <span class="td3 heading">
                        Policy Number
                      </span>

                      <span class="td3 heading">
                        Medicare Id
                      </span>

                      <span class="td2 heading">
                        Submitted Date
                      </span>

                      <span class="td2 heading">
                        Effective Date
                      </span>

                      <span class="td2 heading">
                        Carrier
                      </span>

                      <span class="td3 heading">
                        Agent name
                      </span>

                      <span class="td1 heading">
                        Agent npn
                      </span>

                      <span class="td3 heading">
                        Current status
                      </span>

                      <span class="td2 heading">
                        Report Type
                      </span>

                      <br>
                    </li>
                  </ul>

                  <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                    <li>
                      <span class="td1">
                        <i v-if="isOlderThan120Days(element.EffectiveDate)" class="fa fa-plus-circle fym-good text-sm opacity-10" title="> 120 days"></i>&nbsp;
                        <i v-else class="fa fa-minus-circle fym-alert text-sm opacity-10" title="< 120 days"></i>
                        &nbsp;{{ element.policy_age }} days
                      </span>

                      <span class="td3" :title="'App pol key: ' + element.app_pol_key">
                        {{element.Policy_Number}}
                      </span>

                      <span class="td3">
                        {{element.MedicareID_Masked}}
                      </span>

                      <span class="td2">
                        {{new Date(element.SubmitDate).toLocaleDateString()}}
                      </span>

                      <span class="td2">
                        {{new Date(element.EffectiveDate).toLocaleDateString()}}
                      </span>

                      <span class="td2">
                        {{element.carrier}}
                      </span>

                      <span class="td3" :title="element.MinorProduct">
                        {{element.agent_name}}
                      </span>

                      <span class="td1">
                        {{element.AgentNPN}}
                      </span>

                      <span class="td3">
                        <a v-if="element.appeal" :href="void(0)" @click.prevent="editAppeal(element.appeal)">{{element.Status}} <i class="fa fa-hand-point-up fym-fail text-sm opacity-10" title="Appeal"></i></a>
                        <a v-else :href="void(0)"  @click.prevent="createAppeal(element.app_pol_key)">{{element.Status}}</a> 
                      </span>

                      <span class="td2">
                        {{element.payment_type}}
                      </span>

                      <br>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-12">
                <br>
                <argon-button :disabled="isCreateButtonDisabled" color="success" size="sm" class="ms-auto" @click.prevent="createManualEnrollment(searchVal)">Create Manual Enrollment</argon-button>
                <br>
                <br>
              </div>
              
              <div class="col-md-12 scrollable-div">
                <div class="content">
                  <ul class="ul">
                    <li>

                      <span class="td2 heading">
                        Client MBI
                      </span>

                      <span class="td2 heading">
                        Submitted Date
                      </span>

                      <span class="td2 heading">
                        Effective Date
                      </span>

                      <span class="td2 heading">
                        Carrier
                      </span>

                      <span class="td3 heading">
                        Plan Id
                      </span>

                      <span class="td1 heading">
                        Sunfire Conf Num
                      </span>

                      <br>
                    </li>
                  </ul>

                  <ul class="ul" v-for="element in elements2" v-bind:key="element.id">
                    <li>
                      <span class="td2">
                        <i v-if="isOlderThan120Days(element.effective_date)" class="fa fa-plus-circle fym-good text-sm opacity-10" title="> 120 days"></i>&nbsp;
                        <i v-else class="fa fa-minus-circle fym-alert text-sm opacity-10" title="< 120 days"></i>
                        &nbsp;{{ element.client_mbi }}
                      </span>

                      <span class="td2">
                        {{new Date(element.submitted_date).toLocaleDateString()}}
                      </span>

                      <span class="td2">
                        {{new Date(element.effective_date).toLocaleDateString()}}
                      </span>

                      <span class="td2">
                        {{element.carrier}}
                      </span>

                      <span class="td3" :title="element.plan_id">
                        {{element.plan_id}}
                      </span>

                      <span class="td1">
                        {{element.sunfire_conf_num}}
                      </span>

                      <br>
                    </li>
                  </ul>
                </div>
              </div>

              </div>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
    </div>
  </main>

</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

import "@/assets/css/fontawesome.css";

import * as XLSX from "xlsx";

const company = sessionStorage.getItem("company");
var message = "";
var selectedFilter = 4;
var selectedFilter2 = 1;

var selectedYear = String(new Date().getFullYear());
var selectedMonth = new Date().getMonth();
var selectedPeriod = '1';

export default {
  name: "PolicyReports",
  data() {
    return {
      isModalVisible: false, // Controls modal visibility
      modalComponent: null, // Dynamically loaded component
      showMenu: false,
      elements: [],
      elements2: [],
      company,
      filters: [],
      selectedFilter,
      filters2: [],
      selectedFilter2,
      searchVal: '',
      message,
      role: sessionStorage.getItem('role'),
      years: [{'id':2025,'name':'2025'}, {'id':2024,'name':'2024'}, {'id':2023,'name':'2023'}],
      selectedYear,
      months: [{'id':1,'name':'January'}, {'id':2,'name':'February'},{'id':3,'name':'March'}, {'id':4,'name':'April'},
      {'id':5,'name':'May'}, {'id':6,'name':'June'},{'id':7,'name':'July'}, {'id':8,'name':'August'},
      {'id':9,'name':'September'}, {'id':10,'name':'October'},{'id':11,'name':'November'}, {'id':12,'name':'December'}
      ],
      selectedMonth,
      periods: [{id:'0','name':'All'}, {id:'1','name':'Period 1'}, {id:'2','name':'Period 2'}],
      selectedPeriod,
      isCreateButtonDisabled: true,
    }
  },
  computed: {
    now() {
      return new Date(); // or use Date.now() if you prefer timestamps
    },
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    createManualEnrollment(npn) {
      this.$router.push('/ManualEnrollment/' + npn);
    },
    createAppeal(id) {
      this.$router.push('/Appeal/' + id);
    },
    editAppeal(id) {
      this.$router.push('/Appeal/' + id);
    },
    isOlderThan120Days(effectiveDate) {
      const effectiveDateObj = new Date(effectiveDate);
      const differenceInDays = (this.now - effectiveDateObj) / (1000 * 60 * 60 * 24);
      return differenceInDays > 120;
    },
    async getData(year, month, period, selectedFilter, searchVal) {

      //console.log(year, month, period, selectedFilter, searchVal);

      let response = await fetch("/apis/policy_reports/" + year + '/' + month + '/' + period + '/' + selectedFilter + '/' + searchVal);
      this.elements = await response.json();

      // Count occurrences of each status
      const statusCounts = this.elements.reduce((acc, item) => {
          acc[item.Status] = (acc[item.Status] || 0) + 1;
          return acc;
      }, {});

      //console.log(statusCounts);

      this.message = this.elements.length + ' Policies';

      // Append each category and count to the message
      const statusSummary = Object.entries(statusCounts)
          .map(([status, count]) => `${status}: ${count}`)
          .join(', ');

      // Append to this.message
      this.message += ` (${statusSummary})`;

      //console.log(this.message);

    },
    async getData2(year, month, period, selectedFilter, searchVal) {

    //console.log(year, month, selectedFilter, searchVal);

    let response2 = await fetch("/apis/manual_enrollments/" + year + '/' + month + '/' + period + selectedFilter + '/' + searchVal);
    this.elements2 = await response2.json();
    },
    async getLookups() {        
      let response4 = await fetch("/apis/get_lu/filters");
      this.filters = await response4.json();

      let response5 = await fetch("/apis/get_policy_reports_statuses");
      this.filters2 = await response5.json();
    },
    search() {   
      console.log('searching during ' + this.selectedYear + ' ' + this.selectedMonth + ' ' + this.selectedPeriod + ' ' + this.selectedFilter + ' for: ' + this.searchVal);   
      this.getData(this.selectedYear, this.selectedMonth, this.selectedPeriod, this.selectedFilter, this.searchVal);
      //this.getData2(this.selectedYear, this.selectedMonth, this.selectedPeriod, this.selectedFilter, this.searchVal);
    },
    filter() {
      //console.log(this.selectedFilter);
      this.searchVal = ''; 
    },
    filter2() {
      console.log(selectedFilter2);
    },
    validate() {
      if(this.selectedFilter === 1 && this.searchVal !== '') {
        this.isCreateButtonDisabled = false;
      }
      else {
        this.isCreateButtonDisabled = true;
      }
    },
    exportToExcel() {
      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(this.elements);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Policy Reports");

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, "policy_reports_data.xlsx");
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData(this.selectedYear, this.selectedMonth, this.selectedPeriod, 4,undefined);
    //this.getData2(selectedYear, selectedMonth, selectedPeriod, 4,undefined);
    this.getLookups();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>

<style>

  .scrollable-div {
    width: 1024px; /* Fixed width */
    overflow-x: scroll; /* Enables horizontal scrolling */
    overflow-y: hidden; /* Hides vertical scrolling */
    white-space: nowrap; /* Prevents content from wrapping */
    border: 1px solid #ddd; /* Optional: For visual clarity */
    padding: 10px; /* Optional: For spacing inside the div */
  }

  .scrollable-div::-webkit-scrollbar {
    height: 10px; /* Makes scrollbar visible and sets height */
  }

  .scrollable-div::-webkit-scrollbar-thumb {
    background: #888; /* Style the scrollbar thumb */
    border-radius: 5px; /* Rounded edges */
  }

  .scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color when hovered */
  }

  .content {
    width: 1024px; /* Ensure the content inside is wider than the div */
  }

  .modal-container {
    position: fixed; /* Fixed to the viewport */
    top: 0;
    left: 0;
    width: 100%; /* Full-screen overlay */
    height: 100%; /* Full height of the viewport */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it stays above other elements */
  }

  .modal-content {
    background: #fff; /* White background for modal */
    width: 80%; /* Set to 60% of the viewport width */
    height: 80%; /* Set to 60% of the viewport height */
    max-width: 1024px; /* Add a max-width to prevent the modal from becoming too wide */
    max-height: 768px; /* Add a max-height for better control */
    border-radius: 8px; /* Smooth corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Add a subtle shadow */
    overflow: auto; /* Scroll content if it overflows */
    padding: 20px; /* Inner spacing for content */
    box-sizing: border-box; /* Include padding in width and height */
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

</style>