import { createRouter, createWebHistory } from "vue-router";

import Profile from "../views/Profile.vue";
//import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Home from "../views/Home.vue";
import Company from "../views/Company.vue";
import Dashboard from "../views/Dashboard.vue";

import Aboutus from "../views/Aboutus.vue";
import Docs from "../views/Docs.vue";

import Links from "../views/Links.vue";
import Reports from "../views/Reports.vue";
import Enrollments from "../views/Enrollments.vue";
import DevSpace from "../views/DevSpace.vue";
import MyWorkflow from "../views/MyWorkflow.vue";
import Contracts from "../views/Contracts.vue";
import Contract from "../views/Contract.vue";
import Carriers from "../views/Carriers.vue";
import Carrier from "../views/Carrier.vue";
import CarrierRate from "../views/CarrierRate.vue";
import Agencies from "../views/Agencies.vue";
import Agency from "../views/Agency.vue";
import AgencyAdmins from "../views/AgencyAdmins.vue";
import AgencyAdmin from "../views/AgencyAdmin.vue";
import AgentManagers from "../views/AgentManagers.vue";
import AgentManager from "../views/AgentManager.vue";
import Agents from "../views/Agents.vue";
import AgentSubset from "../views/AgentSubset.vue";
import Agent from "../views/Agent.vue";
import AgencyRates from "../views/AgencyRates.vue";
import AgencyRate from "../views/AgencyRate.vue";
import Overrides from "../views/Overrides.vue";
import Override from "../views/Override.vue";
import AgentRates from "../views/AgentRates.vue";
import AgentRate from "../views/AgentRate.vue";

import Loans from "../views/Loans.vue";
import Loan from "../views/Loan.vue";
import Repayments from "../views/Repayments.vue";
import Repayment from "../views/Repayment.vue";

import Xano from "../views/Xano.vue";
import PolicyReports from "../views/PolicyReports.vue";
import Pdp from "../views/Pdp.vue";
import Analytics from '../views/Analytics.vue';

import Parent from "../views/parent.vue";
import ChildComponent from "../views/child.vue";

const routes = [
	{
		path: "/",
		name: "/",
		component: Home,
		//redirect: "/home",
	  },
	  {
		path: "/profile",
		name: "Profile",
		component: Profile,
		props: true
	  },
	  {
		path: "/signin",
		name: "Signin",
		component: Signin,
	  },
	  {
		path: "/home",
		name: "Home",
		component: Home,
		meta: { title: 'FYM Financial' }
	  },
	  {
		path: "/Company",
		name: "Company",
		component: Company,
	  }, 
	  {
		path: "/aboutus",
		name: "Aboutus",
		component: Aboutus,
	  }, 
	  {
		path: "/docs",
		name: "Docs",
		component: Docs,
	  },
	  {
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
	  },
	  {
		path: "/DevSpace",
		name: "DevSpace",
		component: DevSpace,
	  },
	  {
		path: "/Parent",
		name: "Parent",
		component: Parent,
	  },
	  {
		path: '/ManualEnrollment/:agent_npn',
		name: 'ManualEnrollment',
		component: () => import('../views/ManualEnrollment.vue'), // Lazy-load the component
		props: true
	  },
	  {
		path: '/Appeal/:id',
		name: 'Appeal',
		component: () => import('../views/Appeal.vue'), // Lazy-load the component
		props: true
	  },
	  {
		path: "/MyWorkflow",
		name: "MyWorkflow",
		component: MyWorkflow,
	  },
	  {
		path: "/Links",
		name: "Links",
		component: Links,
	  },
	  {
		path: "/Reports",
		name: "Reports",
		component: Reports,
	  },
	  {
		path: "/Contracts",
		name: "Contracts",
		component: Contracts,
	  },
	  {
		path: "/Contract/:id",
		name: "Contract",		
		component: Contract,
		props: true
	  },
	  {
		path: "/Carriers",
		name: "Carriers",
		component: Carriers,
	  },
	  {
		path: "/Carrier/:id",
		name: "Carrier",		
		component: Carrier,
		props: true
	  },
	  {
		path: "/CarrierRate/:id",
		name: "CarrierRate",		
		component: CarrierRate,
		props: true
	  },
	  {
		path: "/Agencies",
		name: "Agencies",
		component: Agencies,
	  },
	  {
		path: "/Agency/:id",
		name: "Agency",		
		component: Agency,
		props: true
	  },
	  {
		path: "/AgencyAdmins",
		name: "AgencyAdmins",
		component: AgencyAdmins,
	  },
	  {
		path: "/AgencyAdmin/:id",
		name: "AgencyAdmin",		
		component: AgencyAdmin,
		props: true
	  },
	  {
		path: "/AgentManagers",
		name: "AgentManagers",
		component: AgentManagers,
	  },
	  {
		path: "/AgentManager/:id",
		name: "AgentManager",		
		component: AgentManager,
		props: true
	  },
	  {
		path: "/Agents",
		name: "Agents",
		component: Agents,
	  },
	  {
		path: "/AgentSubset/:type",
		name: "AgentSubset",
		component: AgentSubset,
		props: true
	  },
	  {
		path: "/Enrollments",
		name: "Enrollments",
		component: Enrollments,
	  },
	  {
		path: "/Agent/:id",
		name: "Agent",		
		component: Agent,
		props: true
	  },
	  {
		path: "/Loans",
		name: "Loans",
		component: Loans,
	  },
	  {
		path: "/Loan/:id",
		name: "Loan",		
		component: Loan,
		props: true
	  },
	  {
		path: "/Repayments",
		name: "Repayments",
		component: Repayments,
	  },
	  {
		path: "/Repayment/:id",
		name: "Repayment",		
		component: Repayment,
		props: true
	  },
	  {
		path: "/AgencyRates",
		name: "AgencyRates",
		component: AgencyRates,
	  },
	  {
		path: "/AgencyRate/:id",
		name: "AgencyRate",		
		component: AgencyRate,
		props: true
	  },
	  {
		path: "/AgentRates",
		name: "AgentRates",
		component: AgentRates,
	  },
	  {
		path: "/AgentRate/:id/:agent_id",
		name: "AgentRate",		
		component: AgentRate,
		props: true
	  },
	  {
		path: "/Overrides",
		name: "Overrides",
		component: Overrides,
	  },
	  {
		path: "/Override/:id/:agent_id",
		name: "Override",		
		component: Override,
		props: true
	  },
	  {
		path: "/Xano",
		name: "Xano",
		component: Xano,
	  },
	  {
		path: "/PolicyReports",
		name: "Policy Reports",
		component: PolicyReports,
	  },
	  {
		path: "/Pdp",
		name: "Pdp",
		component: Pdp,
	  },
	  {
		path: "/Analytics",
		name: "Analytics",
		component: Analytics,
	  },
	  { path: '/:pathMatch(.*)*', component: Home },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
